import React, { useEffect } from "react";
import auth0 from "auth0-js";

import { getConfig } from "../config";
import { postMessage } from "./webViewHelper";
import Loading from "../components/Loading";

const { seamlessLoginPageClientId, domain, host } = getConfig();
const webAuth = new auth0.WebAuth({
  domain,
  clientID: seamlessLoginPageClientId,
  redirectUri: `${host}/mobilesso?sso_completed=true`,
  responseType: "id_token",
});

const isSsoCompleted = () => {
  const searchParams = new URLSearchParams(document.location.search);
  return searchParams.get("sso_completed") === "true";
};

const callbackFromMobileApp = (json) => {
  localStorage.setItem("targetUri", json.redirectUri);
  try {
    const user_id = json.userId;
    const accessToken = json.accessToken;
    webAuth.login(
      {
        email: `${user_id}@user.id`,
        password: accessToken,
      },
      (err) => postMessage({ action: "log-error", data: err })
    );
  } catch (e) {
    console.log(e.stack);
    postMessage({ action: "log-error", data: e });
    throw e;
  }
};

// mobile app will call this function
window.callbackFromMobileApp = callbackFromMobileApp;

/**
 * Issue where MFA is being challenged after the login
 * Temp solution to delay the redirect which prevents this while more investigations are done
 */
const SsoCompleteRedirectDelayMs = 2000;

const MobileSSOComponent = () => {
  useEffect(() => {
    if (isSsoCompleted()) {
      const url = localStorage.getItem("targetUri");
      setTimeout(() => {
        window.location.href = `${url}`;
      }, SsoCompleteRedirectDelayMs);
    } else {
      // Start SSO Login
      postMessage({ action: "mobilesso" });
    }
  }, []);

  return <Loading />;
};

export default MobileSSOComponent;
