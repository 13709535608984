// webViewHelper.js
const isWebViewContext = () => !!window.ReactNativeWebView;

/**
 *
 * @param {{ action: "mobilesso" | "download" | "log-error"; data: unknown }} message
 * @returns {Promise<'success' | 'error'>}
 */
const postMessage = (message) => {
  return window.ReactNativeWebView?.postMessage(JSON.stringify(message));
};

export { isWebViewContext, postMessage };
